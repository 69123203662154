import React from "react";
import Icon from "@ant-design/icons";

const DeleteIcon = () => (
  <svg
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7498 0.744141H3.49979V3.65039H0.767181V3.70703H0V5.70703H0.767181V22.7444H17.2315V5.70703H18V3.70703H17.2315V3.65039H13.7498V0.744141ZM3.49979 5.70703V5.7071H13.7498V5.70703H15.2315V20.7444H2.76718V5.70703H3.49979ZM11.7498 3.65039V2.74414H5.49979V3.65039H11.7498ZM5.66202 18.3248V8.06836H7.66202V18.3248H5.66202ZM10.4841 8.06836V18.3248H12.4841V8.06836H10.4841Z"
      fill="#FF0000"
    />
  </svg>
);

const PopoverDelete = (props: any) => (
  <Icon component={DeleteIcon} {...props} />
);

export default PopoverDelete;
