import React from "react";
import Icon from "@ant-design/icons";

const CkEditSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clip-Rule="evenodd" d="M16.3875 1.32114C15.7367 0.670298 14.6814 0.670298 14.0306 1.32114L3.13178 12.2194L1.04102 19.5368L8.3586 17.4461L19.2573 6.54788C19.9082 5.897 19.9083 4.84168 19.2573 4.1908L16.3875 1.32114ZM15.2091 2.49967L18.0789 5.36934L15.0346 8.41351L12.1648 5.54384L15.2091 2.49967ZM10.9862 6.72232L4.61566 13.0926L3.46775 17.1101L7.4855 15.9622L13.856 9.59199L10.9862 6.72232Z" fill="currentColor"/>
  </svg>
);

const CkEditIcon = (props: any) => (
  <Icon component={CkEditSvg} {...props} />
);

export default CkEditIcon;
