import React from "react";
import Icon from "@ant-design/icons";

const CkDangerSvg = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8.5" y="1.10652" width="9.74885" height="9.74885" transform="rotate(45 8.5 1.10652)" stroke="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.89941 10.3994V9.39941H9.89941V10.3994H8.89941Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.89941 10.3994V9.39941H7.89941V10.3994H6.89941Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.89941 8.39941V5.39941H9.89941V8.39941H8.89941Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.89941 8.39941V5.39941H7.89941V8.39941H6.89941Z" fill="currentColor"/>
  </svg>
);

const CkDangerIcon = (props: any) => (
  <Icon component={CkDangerSvg} {...props} />
);

export default CkDangerIcon;
