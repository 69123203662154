import React from "react";
import Icon from "@ant-design/icons";

const PrevIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.70001 4.30012L6.65 1.35012C7.04 0.960117 7.67001 0.960117 8.06001 1.35012C8.45001 1.74012 8.45001 2.37012 8.06001 2.76012L6.71 4.11012H18.38C18.93 4.11012 19.38 4.56012 19.38 5.11012C19.38 5.66012 18.93 6.11012 18.38 6.11012H6.92L7.95 7.14012C8.34 7.53012 8.34 8.16012 7.95 8.55012C7.75 8.75012 7.5 8.84012 7.24 8.84012C6.98 8.84012 6.73 8.74012 6.53 8.55012L3.69001 5.71012C3.50001 5.52012 3.4 5.27012 3.4 5.00012C3.4 4.73012 3.51001 4.48012 3.69001 4.29012L3.70001 4.30012Z"
      fill="#2F66F2"
    />
    <path
      d="M4.20001 22.0004H19.38V10.8604H4.20001V22.0004ZM17.38 20.0004H6.2V12.8604H17.38V20.0004Z"
      fill="#2F66F2"
    />
  </svg>
);

const PopoverPrevious = (props: any) => (
  <Icon component={PrevIcon} {...props} />
);

export default PopoverPrevious;
