import React from "react";
import Icon from "@ant-design/icons";

const LeftArrowSvg2 = () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 13L2 7L8 1" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const LeftArrowIcon2 = (props: any) => (
  <Icon component={LeftArrowSvg2} {...props} />
);

export default LeftArrowIcon2;
