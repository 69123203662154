import React from "react";
import Icon from "@ant-design/icons";

const CkWarningSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.84399 13.5L8.5 3.0504L14.156 13.5H2.84399Z" stroke="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 12V11H9V12H8Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 10V7H9V10H8Z" fill="currentColor"/>
  </svg>
);


const CkWarningIcon = (props: any) => (
  <Icon component={CkWarningSvg} {...props} />
);

export default CkWarningIcon;
