import React from "react";
import Icon from "@ant-design/icons";

const NextIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3 4.30012L17.35 1.35012C16.96 0.960117 16.33 0.960117 15.94 1.35012C15.55 1.74012 15.55 2.37012 15.94 2.76012L17.29 4.11012H5.62C5.07 4.11012 4.62 4.56012 4.62 5.11012C4.62 5.66012 5.07 6.11012 5.62 6.11012H17.08L16.05 7.14012C15.66 7.53012 15.66 8.16012 16.05 8.55012C16.25 8.75012 16.5 8.84012 16.76 8.84012C17.02 8.84012 17.27 8.74012 17.47 8.55012L20.31 5.71012C20.5 5.52012 20.6 5.27012 20.6 5.00012C20.6 4.73012 20.49 4.48012 20.31 4.29012L20.3 4.30012Z"
      fill="#2F66F2"
    />
    <path
      d="M19.8 22.0004H4.62V10.8604H19.8V22.0004ZM6.62 20.0004H17.8V12.8604H6.62V20.0004Z"
      fill="#2F66F2"
    />
  </svg>
);

const PopoverNext = (props: any) => <Icon component={NextIcon} {...props} />;

export default PopoverNext;
